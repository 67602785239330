












































import {
	Vue,
	Component,
	Ref,
	PropSync,
	Prop,
} from 'vue-property-decorator'
import CampoDeCnpjOuCpf from './CampoDeCnpjOuCpf.vue'
import { cnpjCpfInvalido } from '@/shareds/regras-de-form'
import { aplicarMascaraParaCpfOculto, aplicarMascaraParaCnpjOculto } from '@/shareds/formatadores'
import { VendaModule } from '@/store/vuex/venda/VendaStore'

@Component({
	components: {
		CampoDeCnpjOuCpf,
	},
})
export default class CampoDeCpfOuCnpjOculto extends Vue {
	@PropSync('value', { type: String }) cpfOuCnpj!: string | null
	@Ref() campo!: HTMLInputElement
	@Ref() campo2!: HTMLInputElement
	@Prop() rules?: ((value: any) => true | string)[]
	emFoco = false
	cnpjCpfInvalido = cnpjCpfInvalido

	get cpfOuCnpjMascarado() {
		return this.mascararCpfOuCnpj(this.retirarFormatacao(this.cpfOuCnpj))
	}

	focus() {
		this.emFoco ? this.campo.focus() : this.campo2.focus()
	}

	onFocus() {
		this.emFoco = true
		setTimeout(() => {
			this.campo.focus()
		})
	}

	mascararCpfOuCnpj(cpfOuCnpj: CampoDeCpfOuCnpjOculto['cpfOuCnpj']) {
		if (!cpfOuCnpj) return ''
		if (cpfOuCnpj.length == 11) {
			return aplicarMascaraParaCpfOculto(cpfOuCnpj)
		} else if (cpfOuCnpj.length == 14) {
			return aplicarMascaraParaCnpjOculto(cpfOuCnpj)
		}
		return cpfOuCnpj
	}

	retirarFormatacao(cpfFormatado: CampoDeCpfOuCnpjOculto['cpfOuCnpj']) {
		if (!cpfFormatado) return ''
		// eslint-disable-next-line no-useless-escape
		return (cpfFormatado || '').replace(/(\.|\/|\-)/g, '')
	}

	get bloquearCampoDeCpfECnpjDoCliente(): boolean {
		const vendaAtual = VendaModule.vendaAtual

		if (!VendaModule.vendaAtual?.cpfDoCliente || !vendaAtual || vendaAtual.itens.length === 0 || !VendaModule.bloquearCampoDeCpfECnpjDoCliente) {
			return false;
		}

		return VendaModule.vendaAtual?.cpfDoCliente.length > 0
	}
}
