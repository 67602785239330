



















































import { ResumoDaVenda, Venda } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { EditarUmaVendaUseCase } from '@/usecases'
import { Vue, Component, Ref, Watch } from 'vue-property-decorator'

@Component
export default class DialogoDeInformarEmail extends Vue {
	@Ref() form!: HTMLFormElement

	mostra: boolean | null = false
	venda: Venda | ResumoDaVenda | null = null
	emailInformado: string | null = null
	emailRules = [
		(v: any) => !!v || 'E-mail é obrigatório',
		(v: any) => /.+@.+\..+/.test(v) || 'E-mail precisa ser válido',
	]
	loading = false

	editarUmaVendaUseCase = new EditarUmaVendaUseCase()

	mostrar(venda: Venda | ResumoDaVenda) {
		this.mostra = true
		this.venda = venda
	}

	fechar(){
		this.mostra = false
	}

	async informarEmail() {
		if (!this.venda) return
		if (!this.emailInformado) return

		try {
			this.loading = true
			await this.editarUmaVendaUseCase.enviarEmail(this.venda.id, this.emailInformado)

			AlertModule.setSuccess('Email enviado com sucesso!')
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.loading = false
			this.mostra = false
			this.emailInformado = null
		}
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean, mostraAnterior: boolean) {
		if (mostra && !mostraAnterior) {
			window.requestAnimationFrame(() => {
				this.form.resetValidation()
			})
		}
	}

}
