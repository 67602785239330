






























































































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import { Loja, PdvComOperador, PontoDeVenda, Usuario } from '@/models'
import { FindUsuarioUseCase } from '@/usecases'
import type { LojaServiceAdapter } from '@/usecases'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import Axios, { CancelTokenSource } from 'axios'
import { Inject } from 'inversify-props'
import Confirmacao from '../ui/Confirmacao.vue'
import { VendaModule } from '@/store/vuex/venda/VendaStore'

@Component({
	components: {
		SeletorDeLojasDoUsuario,
		Confirmacao,
	},
})
export default class SeletorDePdv extends Vue {
	@Ref() cardsDePdv!: HTMLDivElement[]
	@Ref() abrirConfirmacaoPdvTeste!: Confirmacao
	loja: Loja | null = null
	usuario: Usuario | null = null
	tab = null
	pdv: PontoDeVenda | null = null
	pdvs: PdvComOperador[] = []
	buscandoPdvs = false
	cancelToken: CancelTokenSource | null = null
	lojaId = ''

	@Inject('LojaServiceAdapter')
	lojaService!: LojaServiceAdapter

	get possuiAcessoIrrestrito() {
		return UserLoginStore.permiteRegraDeNegocio('acesso-irrestrito')
	}

	pontosDeVendaDisponiveis() {
		if (!this.loja || !this.loja.id) return []
		const usuarioTemPdvDaLojaAssociado = this.usuario?.pdvs.some(
			pdvDoUsuario => pdvDoUsuario.loja.id === this.loja?.id
		)
		if (usuarioTemPdvDaLojaAssociado && !this.possuiAcessoIrrestrito) {
			const pdvsDisponiveis = this.loja.pdvs.filter(
				pdv => this.usuario && this.usuario.pdvs.some(({ id }) => id === pdv.id),
			)
			return pdvsDisponiveis
		}
		return this.loja.pdvs
	}

	selecionarPdv(pdv: PontoDeVenda | null, loja: Loja | null) {
		if(!loja) {
			pdv = this.pdv
			loja = this.loja 
		}
		if (!loja) {
			AlertModule.setError('Nenhuma loja selecionada')
			return
		}
		this.$emit('selecionar', {
			...pdv,
			loja,
		})

		VendaModule.setLojaDaVenda(loja)
	}

	async created() {
		try {
			if (!UserLoginStore.usuario) throw new Error('Nenhum usuário logado')
			this.usuario = (await new FindUsuarioUseCase().findById(UserLoginStore.usuario?.id))
		} catch (e) {
			AlertModule.setError(e)
		}
		document.addEventListener('keydown', this.atalhoDePdv)
	}

	destroyed() {
		document.removeEventListener('keydown', this.atalhoDePdv)
	}

	atalhoDePdv(event: KeyboardEvent) {
		if (event.altKey && event.key.toLowerCase().match(/[0-9]/)) {
			event.preventDefault()
			event.stopPropagation()
			const indice = Number(event.key) - 1
			if (!this.cardsDePdv[indice]) return
			this.cardsDePdv[indice].click()
		}
	}

	get pdvsDisponiveis() {
		const usuarioTemPdvDaLojaAssociado = this.usuario?.pdvs.some(
			pdvDoUsuario => pdvDoUsuario.loja.id === this.loja?.id
		)
		return this.possuiAcessoIrrestrito || !usuarioTemPdvDaLojaAssociado
			? this.pdvs
			:  this.pdvs.filter(
				pdv => this.usuario && this.usuario.pdvs.some(({ id }) => id === pdv.id),
			)
	}

	get pdvsOrdenados(){
		return [...this.pdvsDisponiveis].sort(
			(pdvA, pdvB) => pdvA.nome.localeCompare(pdvB.nome),
		)
	}

	async buscarPdvsDaLoja(loja: Loja | null) {
		this.pdvs = []
		if (this.cancelToken) this.cancelToken.cancel()
		if (!loja) return
		this.buscandoPdvs = true
		try {
			this.cancelToken = Axios.CancelToken.source()
			const pagina = await this.lojaService.getPdvsDaLoja(loja.id, {
				cancelToken: this.cancelToken.token,
				params: {
					size: 100,
				},
			})
			this.pdvs = pagina.content
			this.buscandoPdvs = false
		} catch (error: any) {
			if (Axios.isCancel(error)) {
				this.buscandoPdvs = false
			}
			AlertModule.setError(error)
		}
	}

	adicionarCorNoCard(pdv: PontoDeVenda) {
		return pdv.serieFiscal?.ambiente === "Homologação" ? "#60269E" : "#00000"
	}

	validarPdvHabilitado(pdv: PontoDeVenda) {
		return pdv.aberto || !pdv.ativo
	}

	clickPdv(pdv: PontoDeVenda) {
		return pdv.serieFiscal?.ambiente === 'Homologação' ? this.pdvAtual(pdv, this.loja) : this.selecionarPdv(pdv, this.loja)
	}

	@Watch('loja', { immediate: true })
	onChangeLoja(loja: Loja | null) {
		this.buscarPdvsDaLoja(loja)
	}

	pdvAtual(pdv: PontoDeVenda | null, loja: Loja | null ) {
		this.pdv = pdv
		this.loja = loja
		this.abrirConfirmacaoPdvTeste.mostrar();
	}
}
