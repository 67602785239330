


































































































































import { Vue, Component, PropSync, Ref, Watch, Prop } from 'vue-property-decorator'
import { MovimentacaoDeCaixa, MotivoDaMovimentacao } from '@/models'
import type { FormMovimentacaoDeCaixa } from '@/models'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import { criarMovimentacaoDeCaixa } from '@/shareds/venda-shareds'
import { obrigatorio, maiorQueZero } from '@/shareds/regras-de-form'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import moment from 'moment'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { formatarMoeda } from '@/shareds/formatadores'
import { MovimentarCaixaUseCase, FindMotivoDaMovimentacaoUseCase, AnexoUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { uploadFilesToS3 } from '@/shareds/s3/files'
import SeletorDeMovimentacoes from './SeletorDeMovimentacoes.vue'
import { nextTick } from '@/shareds/utils'
import Qriously from 'vue-qriously'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { TipoDeProcesso } from '@/models/anexo/AnexoDosProcessos'

Vue.use(Qriously, { name:"qriously"})

@Component({
	components: {
		CampoDecimal,
		Confirmacao,
		SeletorDeMovimentacoes,
	},
})
export default class DialogoDeNovaMovimentacao extends Vue {
	@PropSync('mostra', { type: Boolean, default: false }) syncMostra!: boolean
	@Prop({ type: Object, default: criarMovimentacaoDeCaixa }) valorInicial!: FormMovimentacaoDeCaixa
	@Prop({ type: String }) turnoDeVendaId!: string
	@Prop({ type: Boolean, default: false }) editar!: boolean
	@Prop({ type: Boolean, default: false }) movimentacaoParaTurnoDoFechamento!: boolean
	@Prop({ type: Boolean, default: false }) usadoEmVenda!: boolean
	@Ref() botoesDeTipo!: {
		$el: HTMLButtonElement
	}[]
	@Ref() campoDeValor!: CampoDecimal
	@Ref() form!: HTMLFormElement
	@Ref() confirmacaoDeMovimentacao!: Confirmacao
	@Ref() confirmacaoDeSangriaMaiorQueEsperado!: Confirmacao
	@Prop({ type: Boolean }) forcarMotivo!: boolean
	@Ref() itensDeMotivo!: { $el: HTMLInputElement }[]
	@Ref() seletorDeMovimentacoes!: SeletorDeMovimentacoes
	@Prop({ type: String }) lojaId!: string

	obrigatorio = obrigatorio
	maiorQueZero = maiorQueZero

	movimentacao = criarMovimentacaoDeCaixa()
	movimentarCaixaUseCase = new MovimentarCaixaUseCase()
	anexoUseCase = new AnexoUseCase()

	qrData = ''
	mensagensDoBotaoDeTipo: string[] = []
	arquivos: File[] = []
	gerandoMovimentacao = false
	mostrarMotivos = false
	tipoDeProcesso = ""
	desabilitarBotaoDeConfirma = false;
	
	motivos: MotivoDaMovimentacao[] = []
	findMotivoUseCase = new FindMotivoDaMovimentacaoUseCase()
	async created(){
		const motivo = await this.findMotivoUseCase.findMotivosDeMovimentacoesByLojaId(
			this.lojaId)
		this.motivos = motivo.content
	}

	@Watch("movimentacao", { deep: true })
	createQrData() {
		if(!this.movimentacao.id) return
		const usuarioId = UserLoginStore.usuario?.id
		this.tipoDeProcesso = this.movimentacao.tipoMovimentacao === "Reforço" ? "Reforco" : this.movimentacao.tipoMovimentacao 
		this.qrData = `${this.extractDomain(window.location.href)}/anexo/${this.tipoDeProcesso}/${this.valorInicial.id}/${usuarioId}`
	}

	extractDomain(url) {
		let domain = url.replace(/^https?:\/\//, '')
		domain = domain.split('/')[0]
		domain = domain.split(':')[0]

		return domain;
	}

	get motivosFiltrados() {
		return this.motivos.filter(
			motivo => motivo.utilizadoEm
				.includes(this.movimentacao.tipoMovimentacao as 'Sangria' | 'Reforço',
				),
		)
	}

	selecionarMotivo(motivo: MotivoDaMovimentacao) {
		this.movimentacao.motivo = motivo
		this.mostrarMotivos = false
		this.focarPrimeiroCampo()
	}

	@Watch('mostra')
	async onChangeMostra(mostra: boolean) {
		if (!mostra) return
		this.movimentacao = JSON.parse(JSON.stringify(this.valorInicial))

		await nextTick()
		this.form.resetValidation()
		if(!this.movimentacao.motivo && this.forcarMotivo) {
			this.mostrarMotivos = true
			await nextTick()
			if (this.itensDeMotivo.length) {
				this.itensDeMotivo[0].$el.focus()
			}
		} else {
			this.focarPrimeiroCampo()
		}
		this.arquivos=[]
	}

	async adicionarMovimentacao() {
		if (!this.form.validate()) return
		if (!this.turnoDeVendaId) {
			AlertModule.setError('Nenhum turno de venda ativo!')
			return
		}

		const pattern = /[~`!@#$%^&*()áÁàÀçÇõÕãÃôÔêÊ\-+={}[\]|\\;:'",<>/?]+/g;
		const espaco = /\s+/g;

		if (this.arquivos.length > 0 && (pattern.test(this.arquivos[0].name) || espaco.test(this.arquivos[0].name))) {
			const anexo = this.arquivos[0];
			let url = anexo.name.replace(espaco, '_');
			
			url = url.replace(pattern, '');
			
			const anexoFormatado = new File([anexo], url, {
				type: anexo.type,
				lastModified: anexo.lastModified,
			});
			this.arquivos[0] = anexoFormatado;
		}

		if (!await this.confirmacaoDeMovimentacao.mostrar()) {
			this.focarPrimeiroCampo()
			return
		}

		if (
			VendaModule.movimentacaoIsMaiorQueDisponivel(this.movimentacao) &&
			!await this.confirmacaoDeSangriaMaiorQueEsperado.mostrar()
		) {
			this.focarPrimeiroCampo()
			return 
		}

		const { movimentacao } = this

		if (!movimentacao.valor && movimentacao.tipoMovimentacao !== 'Abertura')
			throw new Error('Não informado valor para movimentação')

		this.gerandoMovimentacao = true

		try {
			movimentacao.dataHoraMovimentacao = moment().toDate()
			const movimentacaoAtualizada = movimentacao.id
				? await this.movimentarCaixaUseCase.update(this.turnoDeVendaId, movimentacao.id, movimentacao as MovimentacaoDeCaixa)
				: await this.movimentarCaixaUseCase.execute(this.turnoDeVendaId, movimentacao as MovimentacaoDeCaixa)
			if (this.usadoEmVenda) {
				movimentacao.id
					? VendaModule.atualizaMovimentacao(movimentacaoAtualizada)
					: VendaModule.addMovimentacao(movimentacaoAtualizada)
			}

			if (this.arquivos && this.arquivos.length > 0) {
				const anexoId = await this.anexoUseCase.findAnexoIdByIdentificador(movimentacaoAtualizada.id)
				const informacoesDosArquivos = await uploadFilesToS3(
					this.arquivos, `anexo/movimentacoes-caixa/${movimentacao.tipoMovimentacao}/${this.turnoDeVendaId}`,
				)
				const arquivosComExtensao = informacoesDosArquivos.map(
					({ config }) => config.url?.split("?")[0] || "",
				).filter(url => url && url.length > 0)

				const tipoMovimentacao = movimentacao.tipoMovimentacao === 'Reforço' ? 'Reforco' : movimentacao.tipoMovimentacao

				const tipo = (this.tipoDeProcesso || tipoMovimentacao) as TipoDeProcesso
				await this.anexoUseCase.createOuUpdate({
					id: anexoId,
					urlDosArquivos: arquivosComExtensao,
					idDoProcesso: movimentacaoAtualizada.id,
					tipoDeProcesso: tipo,
				})
			}

			this.$emit('atualizarLista')
			this.$emit('update:movimentacao', movimentacaoAtualizada)
			this.$emit('update:mostra', false)
		} catch (error: any) {
			AlertModule.setError(error)
			this.$emit('update:mostra', false)
		} finally {
			this.gerandoMovimentacao = false
		}
	}

	get tituloDeConfirmacaoDaMovimentacao() {
		return this.editar === true 
			? `Atualizar ${this.movimentacao.tipoMovimentacao} de R$ ${formatarMoeda(this.movimentacao.valor || 0)}?`
			: `Confirma ${this.movimentacao.tipoMovimentacao} de R$ ${formatarMoeda(this.movimentacao.valor || 0)}?`
	}

	get tituloValorMaiorQueEsperado() {
		return `Valor de ${formatarMoeda(this.movimentacao.valor || 0)} R$ é maior que o esperado no PDV`
	}

	async focarPrimeiroCampo() {
		await nextTick()
		this.campoDeValor.focus()
	}
}

