


















import { Vue, Component } from 'vue-property-decorator'
import { VendaModule } from '@/store/vuex/venda/VendaStore'

@Component
export default class DialogoDeVendaDesatualizada extends Vue {
	VendaModule = VendaModule

	get visivel() {
		return VendaModule.vendaDesatualizada
	}

	recarregar() {
		window.location.reload()
	}

	fechar() {
		this.$router.push('/')
	}

	localStorageModificado() {
		VendaModule.verificarVendaDesatualizada()
	}

	created() {
		window.addEventListener('storage', this.localStorageModificado)
		VendaModule.verificarVendaDesatualizada()
	}
	destroyed() {
		window.removeEventListener('storage', this.localStorageModificado)
	}
}
