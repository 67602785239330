






















































import SeletorDeUsuario from '@/components/usuario/SeletorDeUsuario.vue'
import { TurnoDeVenda, UsuarioBase, Venda } from '@/models'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { Vue, Component, Ref, Watch } from 'vue-property-decorator'

@Component({
	components: {
		SeletorDeUsuario,	
	},
})
export default class DialogoDeInformarVendedor extends Vue {
	@Ref() form!: HTMLFormElement
	mostra: boolean | null = false
	vendedores: UsuarioBase[] | null = null
	vendedor: UsuarioBase | null = null

	get venda() {
		return VendaModule.vendaAtual as Venda
	}
	
	set venda(venda: Venda) {
		VendaModule.setVendaAtual(venda)
	}

	get turno() {
		return VendaModule.turnoDeVenda as TurnoDeVenda
	}

	mostrar() {
		this.mostra = true
		this.vendedores = VendaModule.vendaAtual?.vendedor || null
	}

	fechar(){
		this.mostra = false
	}

	informarVendedor(usuario: UsuarioBase | null ){
		const todosOsItensSemVendedor = this.venda.itens.filter(item => item.vendedor !== null).length === 0

		if(!usuario) {
			this.venda.vendedor = null
			this.mostra = false
			this.verificarMudancaDeVendedor(todosOsItensSemVendedor)
			return
		}
		const vendedor: UsuarioBase = {
			id: usuario.id || '',
			nome: usuario.nome || '', 
			email: usuario.email || null,
			cpf: usuario.cpf || null,
			login: usuario.login || '',
			ativo: usuario.ativo || false,
			perfil: usuario.perfil || null,
			vendedor: usuario.vendedor || false,
			criaFaq: usuario.criaFaq || false,
			autoLoginAoAbrirCaixa: usuario.autoLoginAoAbrirCaixa || false,
			codigoExterno: usuario.codigoExterno || undefined,
		}
		
		this.venda.vendedor = [vendedor]

		this.venda.itens.forEach(item => {
			if (item.vendedor === null && item.quantidade > 0) {
				item.vendedor = vendedor
			}
		})
		this.mostra = false

		this.verificarMudancaDeVendedor(todosOsItensSemVendedor)
	}

	verificarMudancaDeVendedor(todosOsItensSemVendedor) {

		if (this.venda.tipoDeTransacao === 'Venda' && 
			this.venda.tipoNota === 'Saída' && 
			!this.venda.isConsignado && 
			!this.venda.isDemonstracao && 
			!this.venda.isVendaReaberta &&
			this.venda.itens.length > 0 &&
			!todosOsItensSemVendedor
		) {
			this.$emit('confirmarNosItens')
		}
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean, mostraAnterior: boolean) {
		if (mostra && !mostraAnterior) {
			this.vendedores = this.venda.vendedor
			window.requestAnimationFrame(() => {
				this.form.resetValidation()
			})
		}
	}

}
