











































































import {
	Vue,
	Component,
	PropSync,
	Prop,
	Ref,
} from 'vue-property-decorator'
import type { TurnoDeVenda, UsuarioBase, Venda } from '@/models'
import { formatarDecimal } from '@/shareds/formatadores'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import CampoDePercentual from '@/components/ui/CampoDePercentual.vue'
import { FindPerfilUseCase, FindUsuarioUseCase } from '@/usecases'
import SeletorDeUsuario from '@/components/usuario/SeletorDeUsuario.vue'

@Component({
	components: {
		CampoDecimal,
		CampoDePercentual,
		SeletorDeUsuario,
	},
})
export default class BotaoDeVendedorPorItem extends Vue {
	@PropSync('value') vendedor!: UsuarioBase | null
	@Prop() venda!: Venda
	@Prop() turnoDeVenda!: TurnoDeVenda | null
	@Ref() botaoDeAcaoDeVendedor!: { $el: HTMLButtonElement }
	@Ref() campoDeValor!: CampoDecimal | CampoDePercentual
	@Ref() form!: HTMLFormElement
	@Prop({ type: Boolean }) disabled!: boolean

	findUsuarioUseCase = new FindUsuarioUseCase()
	findPerfilUseCase = new FindPerfilUseCase()

	CampoDePercentual = CampoDePercentual
	CampoDecimal = CampoDecimal

	formatarDecimal = formatarDecimal
	mostraMenuDeVendedor = false

	vendedorEmEdicao: UsuarioBase | null = null

	async acaoDeVendedor() {
		this.mostraMenuDeVendedor = !this.mostraMenuDeVendedor
		if (this.vendedor) {
			this.vendedorEmEdicao = this.vendedor
		}
	}

	aplicarVendedor() {
		this.vendedor = this.vendedorEmEdicao
		this.mostraMenuDeVendedor = false
	}
}
