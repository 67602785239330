
































import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { Vue, Component, Ref } from 'vue-property-decorator'
import DialogoDeAberturaDeCaixa from './DialogoDeAberturaDeCaixa.vue'
import DialogoDeInformarVendedor from './DialogoDeInformarVendedor.vue'
import ContagemRegressiva from '../contador/ContagemRegressiva.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'

@Component({
	components: {
		DialogoDeAberturaDeCaixa,
		DialogoDeInformarVendedor,
		ContagemRegressiva,
	},
})
export default class CardDeAberturaDeCaixa extends Vue  {
	@Ref() abrirDialogoDeInformarVendedor!: DialogoDeInformarVendedor

	mostraDialogoDeAbertura = false

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	usuarioLogado = JSON.parse(localStorage.getItem('USUARIO_LOGADO')!)

	created() {
		document.addEventListener('keydown', this.atalhoDeCaixa)
	}

	destroyed() {
		document.removeEventListener('keydown', this.atalhoDeCaixa)
	}

	async autenticarAberturaDoCaixa() {
		if (!this.permiteAutoLoginAoAbrirCaixa && !this.usuarioLogado.autoLoginAoAbrirCaixa && !await VendaModule.autenticarAcao()) return
		this.mostraDialogoDeAbertura = true
	}

	atalhoDeCaixa(event: KeyboardEvent) {
		if (event.altKey && event.key.toLowerCase() === 'a') {
			event.preventDefault()
		}
	}

	logoffCaixa() {
		this.$emit("logoff")
	}

	get tempoParaLogoff() {	
		return localStorage["CONTAGEM_REGRESSIVA"] && 
		JSON.parse(localStorage["CONTAGEM_REGRESSIVA"]) !== '00:00:00' &&
		JSON.parse(localStorage["CONTAGEM_REGRESSIVA"]) !== 'null'
			? JSON.parse(localStorage["CONTAGEM_REGRESSIVA"])
			: UserLoginStore.tempoParaLogoff
	}

	get mostrarContagem() {
		return UserLoginStore.mostrarContagem
	}

	get permiteAutoLoginAoAbrirCaixa() {
		return UserLoginStore.permiteRegraDeNegocio('auto-login-ao-abrir-caixa')
	}
}

