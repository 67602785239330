var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"900","persistent":""},model:{value:(_vm.syncMostra),callback:function ($$v) {_vm.syncMostra=$$v},expression:"syncMostra"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.aplicarFiltros.apply(null, arguments)}}},[_c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_c('h3',[_c('v-icon',{staticClass:"mr-3",attrs:{"data-cy":"voltar-dialogo-de-movimentacoes"},on:{"click":_vm.fechar}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Retorno De Crediário ")],1)]),_c('v-row',{staticClass:"ml-2 mr-2"},[_c('SeletorDeCliente',{attrs:{"label":"Clientes","return-object":""},on:{"clienteNaoExiste":_vm.clienteNaoExiste},model:{value:(_vm.cliente),callback:function ($$v) {_vm.cliente=$$v},expression:"cliente"}}),_c('v-btn',{ref:"btnPesquisar",staticClass:"mt-4 ml-2",attrs:{"color":"primary","type":"submit","disabled":_vm.carregando || !_vm.cliente,"loading":_vm.carregando || _vm.processandoImpressao}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Aplicar ")],1)],1)],1)],1),_c('v-card',{staticClass:"mt-2"},[_c('v-container',{attrs:{"fluid":""}},[(_vm.cliente)?_c('DataTableDeCrud',{attrs:{"items":_vm.titulosFormatados,"headers":_vm.headers,"options":_vm.paginacao,"footer-props":{ itemsPerPageOptions: [5, 10, 15, 30, 50] },"server-items-length":_vm.totalRegistros,"hideDetail":"","hideCreate":"","hideDelete":"","onBuscarPagina":_vm.buscar,"loading":_vm.carregandoLista},on:{"update:options":function($event){_vm.paginacao=$event},"update":function (indice) { return _vm.abrirDialogoDeEdicaoDeTitulo(indice); }},scopedSlots:_vm._u([{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({class:{
									'text-decoration-line-through':
										item.situacao === 'Cancelado',
									'text-decoration-line-through grey--text lighten-2':
										item.situacao === 'Estornado',
									'error--text font-weight-bold':
										item.situacao === 'Aberto',
									'success--text': item.situacao === 'Pago',
								}},on),[_vm._v(" "+_vm._s(item.valor)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.situacao)+" ")])])]}},{key:"item.valorAtualizado",fn:function(ref){
								var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
return [_c('span',_vm._g({class:{
									'text-decoration-line-through':
										item.situacao === 'Cancelado',
									'text-decoration-line-through grey--text lighten-2':
										item.situacao === 'Estornado',
									'error--text font-weight-bold':
										item.situacao === 'Aberto',
									'success--text': item.situacao === 'Pago',
								}},on),[_vm._v(" "+_vm._s(item.valorAtualizado)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.situacao)+" ")])])]}}],null,true),model:{value:(_vm.titulosSelecionados),callback:function ($$v) {_vm.titulosSelecionados=$$v},expression:"titulosSelecionados"}}):_c('div',{staticClass:"text-center grey--text ma-4"},[_c('v-icon',{attrs:{"large":"","color":"grey"}},[_vm._v(" mdi-account-alert ")]),_c('div',{staticClass:"headline"},[_vm._v("Nenhum cliente selecionado")]),_c('div',{staticClass:"caption"},[_vm._v("Escolha um cliente no filtro")])],1)],1)],1)],1),_c('DialogoDeEdicaoDeTitulo',{ref:"dialogoDeEdicao",attrs:{"tipoDePessoa":_vm.tipoDePessoa,"retornoCrediario":true},on:{"atualizar":_vm.buscar,"valor":_vm.baixarTitulo}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }