


































































import type { Venda } from '@/models'
import { formatarMoeda } from '@/shareds/formatadores'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { ValePresenteUseCase } from '@/usecases'
import { Vue, Component, PropSync, Ref } from 'vue-property-decorator'

@Component
export default class DialogoDeValePresente extends Vue {
	@PropSync('value', { type: Object, default: null }) venda!: Venda
	@Ref() campoCodigo!: HTMLInputElement

	valePresenteUseCase = new ValePresenteUseCase()
	
	mostra = false
	codigoDoValePresente = ''
	aplicandoValePresente = false

	formatarMoeda = formatarMoeda

	mostrar() {
		setTimeout(() => {
			this.campoCodigo.focus()
		})
		this.mostra = true
	}

	fecharDialogo() {
		this.codigoDoValePresente = ''
		this.mostra = false
	}

	async aplicarValePresente() {
		if (!this.codigoDoValePresente) {
			AlertModule.setError('Informe um vale-presente válido')
			return
		}

		if(this.loja === null) {
			AlertModule.setError('Ocorreu um erro interno, contate o suporte')
			return
		}

		const valeEstaAplicado = this.venda.valesPresentes.some(
			({ id }) => id === this.codigoDoValePresente,
		)
		const grupoEconomicoId = this.loja.grupoEconomico.id

		if (valeEstaAplicado) {
			AlertModule.setError('O vale-presente já foi aplicado nesta venda.')
			this.aplicandoValePresente = false
			return
		}

	
		try {
			const valePresente = await this.valePresenteUseCase.findValeValido(this.codigoDoValePresente, grupoEconomicoId)
			const valeJaAplicado = this.venda.valesPresentes.some(
				({ id }) => id === valePresente.id,
			)
			if (valeJaAplicado) {
				AlertModule.setError('Vale-presente já foi aplicado à esta venda')
				this.aplicandoValePresente = false
				return
			}

			AlertModule.setSuccess('Vale-presente aplicado com sucesso')
			this.aplicandoValePresente = false

			this.$emit('aplicarValePresenteNaVenda', valePresente)
			this.fecharDialogo()
		} catch(error) {
			AlertModule.setError(error)
		}

		
	}

	get valesPresentes() {
		return this.venda.valesPresentes
	}

	
	get loja() {
		return VendaModule.lojaDaVenda
	}

	formatCodigo(id) {
		const part1 = id.substring(0, 8);
		const part2 = id.substring(id.length - 4);
		return `${part1}...${part2}`;
	}
}
