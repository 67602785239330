












































import { Adquirente, Page, Pageable } from '@/models';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { FindAdquirenteUseCase } from '@/usecases';
import axios, { CancelTokenSource } from 'axios';
import { Component, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';

	@Component
export default class SeletorDeAdquirente extends Vue {
		@Ref() campo!: HTMLInputElement
		@PropSync('value') selecionado?: Adquirente | Adquirente[] | string | string[]
		
		adquirentes: Adquirente[] = []
		carregando = false
		busca: string | null = null
		pageable: Pageable = {
			page: 0,
			size: 3,
		}
		pagina: Page<Adquirente> | null = null
		cancelToken: CancelTokenSource | null = null
		findAdquirenteUseCase = new FindAdquirenteUseCase

		get noDataMessage() {
			if (this.carregando) {
				return 'Carregando...'
			}
			if (!this.busca) {
				return 'Digite para consultar...'
			}
			return '$vuetify.noDataText'
		}

		get carregouTodosOsAdquirentes() {
			return this.pagina?.last || false
		}

		async pageNext() {
			this.pageable.page = !this.pageable.page
				? 1
				: this.pageable.page += 1
			await this.find()
		}


		@Watch('busca', { deep: true })
		onChangeBusca(busca: string, buscaAnterior: string) {
			this.pageable = {
				...this.pageable,
				page: 0,
			}
			if (busca === buscaAnterior) {
				this.carregando = false
				return
			}
			this.find()
		}

		async find() {
			if (this.cancelToken) this.cancelToken.cancel()
			try {
				this.carregando = true
				this.cancelToken = axios.CancelToken.source()
				this.pagina = await this.findAdquirenteUseCase.findAll({
					page: this.pageable.page || 0,
					size: this.pageable.size,
					busca: this.busca || undefined,
				},
				{
					cancelToken: this.cancelToken.token,
				})
				this.pagina.content.forEach(adquirente => {
					if (this.adquirentes.some(({ id }) => adquirente.id === id)) return
					this.adquirentes.push(adquirente)
				})
				this.carregando = false
			} catch (error: any) {
				if (axios.isCancel(error)) return
				this.pagina = null
				AlertModule.setError(error)
			} finally {
				this.carregando = false
			}
		}


		focus() {
			this.campo.focus()
		}
}
