/**
 * @author langaads
 * 
 * Permite a criação de funções que executam e podem ser monitoradas pela aplicação
 * Útil para executar chamadas de API e vincular sua execução a componentes com loading
 */
export default class Tarefa {
	nome: string
	funcao: Function
	emExecucao: boolean

	constructor(nome, funcao) {
		this.nome = nome
		this.funcao = funcao
		this.emExecucao = false
	}

	async executar() {
		if (this.emExecucao) {
			console.warn(`Tarefa '${this.nome}' já está em execucao`)
			return
		}
		try {
			this.emExecucao = true
			return await this.funcao()
		} finally {
			this.emExecucao = false
		}
	}
}