





































































import Confirmacao from '@/components/ui/Confirmacao.vue'
import { PreVendaListagem } from '@/models/pre-venda/PreVenda'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { ImportarPreVendaUseCase } from '@/usecases'
import { PreVendaUseCase } from '@/usecases/venda/PreVendaUseCase'
import { Vue, Component, Watch, Ref, Prop, PropSync } from 'vue-property-decorator'

@Component({
	components: {
		Confirmacao,
	},
})
export default class DialogoDeImportarPreVenda extends Vue {
	@PropSync('mostra', { type: Boolean, default: false }) syncMostra!: boolean
	@Prop({ type: Boolean }) telaDeEmissaoDeNota!: boolean
	@Ref() form!: HTMLFormElement
	@Ref("confirmacao") confirmacao!: Confirmacao

	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	preVendas: PreVendaListagem[] = []
	loading = false
	identificador = ''
	importarPreVendaUseCase = new ImportarPreVendaUseCase()
	preVendaUseCase = new PreVendaUseCase()

	get lojaId() {
		return this.telaDeEmissaoDeNota 
			? VendaModule.lojaDaEntrada?.id
			: VendaModule.lojaDaVenda?.id
	}

	@Watch('syncMostra', { immediate: true })
	async buscarPreVenda() {

		if(!this.syncMostra) return
		this.loading = true

		const params = {
			situacoes: 'Aberto',
			lojaId: this.lojaId,
		}

		try {
			this.preVendas = await this.preVendaUseCase.findPreVendaListagem(params)
			this.loading = false
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	fechar(){
		this.syncMostra = false
	}

	async importarPreVenda(PreVendaListagem: PreVendaListagem) {
		try {
			this.loading = true 
			await this.importarPreVendaUseCase.importarPreVenda(PreVendaListagem.identificador, this.telaDeEmissaoDeNota)
			this.fechar()
		} catch(e) {
			AlertModule.setError(e)
		} finally {
			this.loading = false
		}
	}
}

