
















































import CampoDeItemDeVenda from '@/components/venda/CampoDeItemDeVenda.vue'
import { ItemDaVenda, Venda } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { Vue, Component,  Ref } from 'vue-property-decorator'

@Component({
	components:{
		CampoDeItemDeVenda,
	},
})
export default class DialogoDeRetirarProduto extends Vue {
	@Ref() campoDeBusca!: HTMLInputElement
	mostra = false

	get venda() {
		return VendaModule.vendaAtual
	}

	set venda(venda: Venda | null) {
		VendaModule.setVendaAtual(venda)
	}

	get lojaDaVenda() {
		return VendaModule.lojaDaVenda
	}

	retiraProdutoDaVenda(item: ItemDaVenda){
		if (!this.venda) return

		const venda: Venda = JSON.parse(JSON.stringify(this.venda))

		let saldoAEliminar = item.quantidade
		const sinal = this.venda.tipoDeTransacao === 'Venda'
			? 1
			: -1
		try {
			if (!venda.itens.some(({ produto }) => produto.id === item.produto.id))
				throw new Error('Produto não encontrado na venda')

			while (saldoAEliminar > 0) {
				const indice = venda.itens
					.findIndex(itemDaVenda => itemDaVenda.produto.id === item.produto.id)
				const itemAnterior = venda.itens[indice]
				if (indice === -1) break

				if (saldoAEliminar * sinal >= itemAnterior.quantidade) {
					saldoAEliminar = saldoAEliminar * sinal - itemAnterior.quantidade
					venda.itens.splice(indice, 1)
				} else {
					itemAnterior.quantidade = itemAnterior.quantidade - saldoAEliminar * sinal
					saldoAEliminar = 0
					venda.itens.splice(indice, 1, itemAnterior)
				}
				continue
			}
		} catch (error: any) {
			AlertModule.setError(error)
			return
		}
		this.venda = venda
	}

	mostrar(){
		this.mostra = true
	}
}
 
