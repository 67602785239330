





































import { ParcelasComTaxas, FormPagamento, ParcelasCrediario } from '@/models'
import { Vue, Component } from 'vue-property-decorator'
import DatePicker from '../ui/DatePicker.vue'

@Component({
	components: {
		DatePicker,
	}})
export default class DialogoDeEdicaoDeParcelas extends Vue {
	formPagamento!: FormPagamento

	mostra = false
	carregando = false
	datas: string[] | [] = []
	parcelasCrediario!: ParcelasCrediario[]

	confirmarLista() {
		this.parcelasCrediario = [];

		this.datas.forEach((data, index) => {
			this.parcelasCrediario.push({
				parcela: index + 1,
				vencimento: data,
			} as ParcelasCrediario);
		});
		
		this.$emit('parcelamenteEditado', this.parcelasCrediario);
		this.mostra = false
	}

	mostrar(formPagamento) {
		this.formPagamento = formPagamento
		if(!this.formPagamento.tipoDePagamento?.parcelasComTaxas) return
		this.calcularDatasParcelas()
		this.mostra = true 
		this.formPagamento.tipoDePagamento.parcelasComTaxas.sort(this.compararPorParcela)
	}

	compararPorParcela(a: ParcelasComTaxas, b: ParcelasComTaxas) {
		return a.parcelamentos - b.parcelamentos;
	}
	
	calcularDatasParcelas() {
		if (!this.formPagamento.qtdeDeParcelas || !this.formPagamento.tipoDePagamento || !this.formPagamento.tipoDePagamento.parcelasComTaxas) {
			return;
		}

		const qtdeParcelas = this.formPagamento.qtdeDeParcelas;
		const recorrencia = parseInt(String(this.formPagamento.tipoDePagamento.parcelasComTaxas[0].recorrencia || '0'));

		const dataInicio = new Date();
		dataInicio.setDate(dataInicio.getDate() + recorrencia);
		const dataPrimeiraParcela = dataInicio.toISOString().substr(0,10);
		this.datas = [dataPrimeiraParcela];

		for (let i = 1; i < qtdeParcelas; i++) {
			const dataParcela = new Date(this.datas[i - 1]);
			dataParcela.setDate(dataParcela.getDate() + recorrencia);
			this.datas.push(dataParcela.toISOString().substr(0,10));
		}
	}

}
