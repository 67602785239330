






























































import {
	Vue,
	Component,
	Ref,
	Prop,
	Watch,
} from 'vue-property-decorator'
import { Cliente, Desconto } from '@/models'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import CampoDePercentual from '@/components/ui/CampoDePercentual.vue'
import { FindPerfilUseCase, FindUsuarioUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import SeletorDeCliente from '../clientes/SeletorDeCliente.vue'

@Component({
	components: {
		CampoDecimal,
		CampoDePercentual,
		SeletorDeCliente,
	},
})
export default class BotaoDeConsultaCliente extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() seletorDeCliente!: SeletorDeCliente
	@Prop() grupoEconomicoDaLoja!: string
	cliente: Cliente | null = null

	findUsuarioUseCase = new FindUsuarioUseCase()
	findPerfilUseCase = new FindPerfilUseCase()

	formValid = false
	mostraMenuDeCliente = false

	descontoEmEdicao: Desconto | null = null

	clienteNaoExiste() {
		AlertModule.setInfo('Cliente não encontrado')
	}

	acaoDeConsultarCliente() {
		this.mostraMenuDeCliente = !this.mostraMenuDeCliente
		if (this.mostraMenuDeCliente) {
			setTimeout(() => {
				this.focus()
			}, 100)
			return
		}
	}

	async confirmarCliente() {
		if (!this.form.validate()) return
		this.$emit('acaoDeConsultarCliente', this.cliente)
		this.mostraMenuDeCliente = false
	}

	focus() {
		this.seletorDeCliente.focus()
	}

	@Watch('mostraMenuDeCliente')
	onChangeMostrar() {
		if (!this.mostraMenuDeCliente) {
			this.cliente = null
		}
	}
}
