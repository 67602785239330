




































































import CardDaComandas from '@/components/loja/CardDeComandas.vue';
import StatusDaComanda from '@/components/loja/StatusDaComanda.vue';
import { Comanda } from '@/models';
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { VendaModule } from '@/store/vuex/venda/VendaStore';
import { ComandaUseCase, FindLojaUseCase } from '@/usecases';
import { Component, PropSync, Vue, Watch} from 'vue-property-decorator';

@Component({
	components: {
		CardDaComandas,
		StatusDaComanda,
	},
})
export default class DialogoDeImportarComandas extends Vue {
	@PropSync('mostra', { type: Boolean, default: false }) syncMostra!: boolean

	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	comandas: Comanda[] = []
	loading = false
	findLojaUseCase = new FindLojaUseCase()
	comandaUseCase = new ComandaUseCase()

	voltar() {
		this.syncMostra = false
		this.comandas = []
	}

	get comandasOrdenados() {
		const comandas = this.comandas.length > 0
			? [...this.comandas].sort((comandaA, comandaB) =>
				comandaA.nome.localeCompare(comandaB.nome),
			)
			: []
		return comandas
	}

	get lojaDaVenda() {
		return VendaModule.lojaDaVenda
	}

	@Watch('syncMostra')
	async buscarLoja() {
		if(!this.syncMostra || !this.lojaDaVenda) return
		this.loading = true

		try {
			const comandas = await this.comandaUseCase.findComandasNaoFinalizadasEAbertas(this.lojaDaVenda.id)
			this.comandas = comandas
			this.loading = false
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	async importarComanda(comanda: Comanda) {
		try{
			const comandaJson = localStorage["COMANDA_IMPORTADA"]
			if(comandaJson !== 'null' && comandaJson !== undefined) {
				throw new Error('Uma comanda já está em andamento. Por favor, conclua a venda atual antes de importar uma nova comanda')
			}
			const comandaSalvar: Comanda = {
				id: comanda.id,
				identificador: comanda.identificador,
				dataHoraDeAbertura: comanda.dataHoraDeAbertura,
				aberto: comanda.aberto,
				nome: comanda.nome,
				descricao: comanda.descricao,
				itemDaComanda: comanda.itemDaComanda,
				createdAt: comanda.createdAt,
				updatedAt: comanda.updatedAt,
				taxaDeServico: comanda.taxaDeServico,
				aguardandoProcessamentoNoCaixa: true,
			}

			this.$emit('importarComanda', comanda.itemDaComanda)
			this.$emit('importarTaxaDaComanda', comanda.taxaDeServico)
			const comandaSalva = await this.comandaUseCase.save(comandaSalvar)
			localStorage.setItem("COMANDA_IMPORTADA", JSON.stringify(comandaSalva))
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.syncMostra = false
		}
		
	}
}
