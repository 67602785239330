import printjs from 'print-js'
import { v4 as uuidv4 } from 'uuid'
import pdfMake from 'pdfmake/build/pdfmake'
import virtualFileSystem from 'pdfmake/build/vfs_fonts.js'
import type { TDocumentDefinitions } from 'pdfmake/interfaces'

export default printPdfMake

async function printPdfMake(docDefinition: TDocumentDefinitions) {
	const base64Pdf = await getBase64Pdf(docDefinition)
	await printBase64pdf(base64Pdf)
}

function getBase64Pdf(docDefinition: TDocumentDefinitions): Promise<string> {
	// Clone necessario pois pdfMake.createPdf modifica a docDefinition por referência e causa bugs de layout inesperados
	const docDefinitionClone = JSON.parse(JSON.stringify(docDefinition))

	// Solução provisória para não perder o estilo das linhas ao obter leiaute do local storage
	docDefinitionClone.content
		.filter(({ layout }) => layout !== undefined)
		.map((content: any) => {
			content.layout = {
				hLineWidth: () => 0,
				vLineWidth: () => 0,
			}
			return content
		})
	docDefinitionClone.content
		.filter(({ canvas }) => canvas !== undefined)
		.map((content: any) => {
			content.canvas = [{
				...content.canvas[0],
				y1: 0,
				y2: 0,
			}]
			content = {
				...content,
				_maxHeight: 0,
				_minHeight: 0,
				_maxWidth: 225,
				_minWidth: 225,
			}
			return content
		})
	return new Promise(resolve => {
		pdfMake
			.createPdf(
				docDefinitionClone,
				undefined,
				undefined,
				virtualFileSystem.pdfMake.vfs,
			)
			.getBase64(base64 => resolve(base64))
	})
}

async function printBase64pdf(base64: string): Promise<void> {
	const frameId = `printjs-${uuidv4()}`
	await new Promise((resolve, reject) => {
		printjs({
			printable: base64,
			type: 'pdf',
			frameId,
			base64: true,
			onError: error => reject(error),
			onLoadingEnd: () => resolve(true),
			onPrintDialogClose: () => {
				const iframe = document.getElementById(frameId)
				iframe?.parentNode?.removeChild(iframe)
			},
		})
	})
}
