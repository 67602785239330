













































import { Desconto } from '@/models'
import { Vue, Component} from 'vue-property-decorator'

@Component

export default class DialogoDeDescontoEmGrupo extends Vue{
	mostrarDialogo= false
	desconto: number | null = null
	
	abrirDialogo(desconto: Desconto | null){
		this.desconto = desconto?.valor || null
		this.mostrarDialogo = true
	}

	aplicarDesconto(){
		this.$emit('aplicarDesconto', this.desconto)
		this.mostrarDialogo = false
	}
}
