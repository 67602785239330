
































import { Vue, Component, Prop, Watch  } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { SaveClienteUseCase } from '@/usecases'
import type { Cliente } from '@/models'

@Component
export default class DialogoDeEdicaoEmailCliente extends Vue {
	@Prop({type: Object})cliente?: Cliente
	mostra = false
	salvando = false
	saveClienteUseCase = new SaveClienteUseCase()
	clienteEmEdicao: string | undefined = undefined

	mostrar() {
		this.mostra = true
	}

	esconder() {
		this.clienteEmEdicao = undefined
		this.mostra = false
	}

	async salvar() {
		if (!this.cliente) return
		if (!this.clienteEmEdicao) return

		try {
			this.salvando = true
			this.editarCliente()

			await this.saveClienteUseCase.update(this.cliente)

			
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			
			this.mostra = false
			this.salvando = false
			AlertModule.setSuccess('Cliente salvo com sucesso')
		}
	}

	editarCliente() {
		if(this.cliente && this.clienteEmEdicao) this.cliente.email = this.clienteEmEdicao
	}

	@Watch('cliente')
	onChangeCliente() {
		this.clienteEmEdicao = this.cliente?.email
	}
}
