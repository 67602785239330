var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.movimentacao.anexos.length)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"text-decoration-underline text-caption font-weight-medium",staticStyle:{"cursor":"pointer"},on:{"click":function () { return _vm.baixarArquivo(
						("" + (_vm.extrairCaminho(_vm.movimentacao.anexos[0]))), _vm.extrairNomeDosDocumentos(_vm.movimentacao.anexos[0])
					); }}},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.extrairNomeDosDocumentos(_vm.movimentacao.anexos[0]))+" ")])]}}],null,false,77145150)},[_c('span',[_vm._v("Clique para baixar")])]),(_vm.movimentacao.anexos.length > 1)?_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,false,2054453410)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Anexos")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.movimentacao.anexos),function(anexo,indice){return _c('v-list-item',{key:indice},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-download")])],1),_c('v-list-item-subtitle',{on:{"click":function () { return _vm.baixarArquivo(("" + (_vm.extrairCaminho(anexo))), _vm.extrairNomeDosDocumentos(anexo)); }}},[_vm._v(_vm._s(_vm.extrairNomeDosDocumentos(anexo)))])],1)}),1)],1)],1)],1):_vm._e()],1):_c('div',{staticClass:"font-italic"},[_vm._v(" Sem anexos. ")])])}
var staticRenderFns = []

export { render, staticRenderFns }