



























import { Vue, Component, PropSync, Ref, Prop } from 'vue-property-decorator'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import type { FormDeItemDeFechamento } from '@/models'
import { formatarMoeda } from '../../shareds/formatadores'
import { obrigatorio } from '../../shareds/regras-de-form'

@Component({
	components: {
		CampoDecimal,
	},
})
export default class ItemDaTabelaDeFechamento extends Vue {
	@Ref() campoDeValorContado!: HTMLInputElement
	@Prop({ type: Boolean, default: false }) fecharAsCegas!: boolean
	@PropSync('value') item!: FormDeItemDeFechamento

	formatarMoeda = formatarMoeda
	obrigatorio = obrigatorio

	get diferenca(): number | string {
		if (typeof this.item.valorContado !== 'number') return '-'
		return this.item.valorContado - this.item.valorEsperado
	}

	get diferencaFormatada() {
		if (this.fecharAsCegas) return '-'
		if (typeof this.diferenca !== 'number') return this.diferenca
		return formatarMoeda(this.diferenca)
	}

	get classDiferenca() {
		if (this.fecharAsCegas || typeof this.diferenca !== 'number' || !this.diferenca) {
			return []
		}
		if (this.diferenca < 0) {
			return ['error--text']
		} else {
			return ['success--text']
		}
	}

	public focus() {
		this.campoDeValorContado.focus()
	}
}

