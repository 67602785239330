











import { Vue, Component, Prop } from 'vue-property-decorator'
import { MotivoDaMovimentacao, MovimentacaoDeCaixa } from '@/models'
import { FindMotivoDaMovimentacaoUseCase } from '@/usecases'

@Component
export default class SeletorDeMovimentacoes extends Vue{
	@Prop({ type: Array }) tipos?: MovimentacaoDeCaixa['tipoMovimentacao'][]
	@Prop({ type: String }) lojaId!: string
	motivos: MotivoDaMovimentacao[] = []
	findUseCase = new FindMotivoDaMovimentacaoUseCase()

	async created(){
		const motivo = await this.findUseCase.findMotivosDeMovimentacoesByLojaId(
			this.lojaId)
		this.motivos = motivo.content
		
	}

	get motivosFiltrados() {
		return this.motivos.filter(itensFiltrados => itensFiltrados.utilizadoEm
			.some(tipoUtilizado => !this.tipos || this.tipos.includes(tipoUtilizado)))
	}
}

