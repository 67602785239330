






















































































































































































































import {
	Vue,
	Component,
	Prop,
	PropSync,
	Ref,
	Watch,
} from 'vue-property-decorator'
import {
	Venda,
	MovimentacaoDeCaixa,
	TipoMovimentacao,
	TurnoDeVenda,
} from '@/models'
import ItemDaTabelaDeFechamento from './ItemDaTabelaDeFechamento.vue'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { formatarMoeda, formatarDataHora } from '@/shareds/formatadores'
import DialogoDeNovaMovimentacao from './DialogoDeNovaMovimentacao.vue'
import { criarMovimentacaoDeCaixa } from '@/shareds/venda-shareds'
import BotaoDeDownloadDeAnexoDeFechamento from '@/components/venda/BotaoDeDownloadDeAnexoDeFechamento.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FindTurnoDeVendaUseCase } from '@/usecases'

@Component({
	components: {
		ItemDaTabelaDeFechamento,
		DialogoDeNovaMovimentacao,
		BotaoDeDownloadDeAnexoDeFechamento,
	},
})
export default class DialogoDeMovimentacoes extends Vue {
	@PropSync('mostra', { type: Boolean, default: false }) syncMostra!: boolean
	@Prop() vendas!: Venda[]
	@Ref() itensDaTabelaDeFechamento!: ItemDaTabelaDeFechamento[]
	@Ref() botaoDeSangria!: { $el: HTMLButtonElement }
	@Ref() botaoDeReforco!: { $el: HTMLButtonElement }
	@Prop({ type: String }) lojaId!: string

	formatarMoeda = formatarMoeda
	formatarDataHora = formatarDataHora
	mostraNovaMovimentacao: boolean | null = null
	atualizar: boolean | null = null
	movimentacaoInicial = criarMovimentacaoDeCaixa()
	movimentacoesOrdenadas: MovimentacaoDeCaixa[] | null = null

	findTurnoDeVendaUseCase = new FindTurnoDeVendaUseCase()

	get loja() {
		return VendaModule.lojaDaVenda
	}

	voltar() {
		this.$emit('voltar')
	}

	get turnoDeVenda() {
		return VendaModule.turnoDeVenda as TurnoDeVenda
	}

	obterClassStylesTransacao(movimentacao: MovimentacaoDeCaixa) {
		switch (movimentacao.tipoMovimentacao) {
			case 'Sangria':
				return ['error--text']
			case 'Reforço':
				return ['success--text']
		}
		return []
	}

	get mostrarApenasSemAnexo() {
		return this.$route.query['sem-anexos'] !== undefined ? true : false
	}

	set mostrarApenasSemAnexo(mostra: boolean | null | undefined) {
		this.$router
			.replace({
				name: this.$route.name as string,
				query: { ...this.$route.query, ['sem-anexos']: mostra ? null : undefined },
			})
			.catch()
	}

	created() {
		document.addEventListener('keydown', this.atalhoDeSangria)
		document.addEventListener('keydown', this.atalhoDeReforco)
		this.buscarMovimentacoes()
	}

	@Watch("syncMostra")
	async buscarMovimentacoes() {
		if(!this.turnoDeVenda || this.turnoDeVenda?.id === undefined) return
		try {
			this.movimentacoesOrdenadas = await this.findTurnoDeVendaUseCase.getMovimentacoesCaixaByTurnoId(this.turnoDeVenda.id)
		} catch(e) {
			AlertModule.setError(e)
		}
	}

	destroyed() {
		document.removeEventListener('keydown', this.atalhoDeSangria)
		document.removeEventListener('keydown', this.atalhoDeReforco)
	}

	atalhoDeSangria(event: KeyboardEvent) {
		if (!event.altKey || event.key.toLowerCase() !== 's') return
		if (!this.botaoDeSangria) return
		event.preventDefault()
		event.stopPropagation()
		this.botaoDeSangria.$el.click()
	}

	atalhoDeReforco(event: KeyboardEvent) {
		if (!event.altKey || event.key.toLowerCase() !== 'r') return
		if (!this.botaoDeReforco) return
		event.preventDefault()
		event.stopPropagation()
		this.botaoDeReforco.$el.click()
	}

	get movimentacoesSemAnexos(){
		if(!this.turnoDeVenda || !this.movimentacoesOrdenadas) return []
		return this.movimentacoesOrdenadas.filter(movimentacao=> movimentacao.anexos.length === 0 && movimentacao.tipoMovimentacao !== 'Abertura' )
	}

	get precisaAdicionarAnexoNasMovimentacoes(){
		if(!this.loja) return false
		return ( 
			this.movimentacoesSemAnexos && 
			this.loja.configuracaoDaLoja.adicionarAnexosEmMovimentacoes
		)
	}

	get movimentacoesFiltradas(){
		if(!this.mostrarApenasSemAnexo){
			return this.movimentacoesOrdenadas || []
		} else {
			return this.movimentacoesSemAnexos || []
		}
	}

	mostrarDialogoMovimentacao(movimentacao: TipoMovimentacao | MovimentacaoDeCaixa, atualizar: boolean){
		if (atualizar === false && typeof movimentacao === 'string') {
			this.movimentacaoInicial = criarMovimentacaoDeCaixa()
			this.movimentacaoInicial.tipoMovimentacao = movimentacao
		} else if (atualizar === true && typeof movimentacao === 'object') {
			this.movimentacaoInicial = movimentacao 
		}
		this.atualizar = atualizar
		this.mostraNovaMovimentacao = true
	}
}

