









import { Vue, Ref, Component } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'

@Component({
	directives: {
		mask,
	},
})

export default class CampoDeCodigoDaVenda extends Vue {
	@Ref() campoCodigo!: HTMLInputElement 

	focus() {
		this.campoCodigo.focus()
	}
}
