



















import { Vue, Component } from 'vue-property-decorator'

@Component

export default class DialogoDeAniversarioDoCliente extends Vue {
	mostra = false

	mensagem="Hoje é o aniversário do cliente"

	mostrar() {
		this.mostra = true
	}

	esconder() {
		this.mostra = false
	}
}
