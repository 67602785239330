












import { Component, Prop, Vue } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'

@Component({
	directives: {
		mask,
	},
})
export default class ContagemRegressiva extends Vue {
	@Prop() horaInicial!: string

	contador = 0
	progresso = 100
	intervalId: NodeJS.Timeout | null = null

	created() {
		this.contador = horaParaSegundos(this.horaInicial)
		this.startContador()
	}

	startContador() {
		this.intervalId = setInterval(() => {
			this.contador--

			if (this.contador <= 0) {
				this.stopContador()
			}
		}, 1000)
	}

	stopContador() {
		if (this.intervalId) {
			clearInterval(this.intervalId)
			this.intervalId = null
			this.$emit('logoff')
		}
	}

	get formatarTempo() {
		const hours = Math.floor(this.contador / 3600)
		const minutes = Math.floor((this.contador % 3600) / 60)
		const seconds = this.contador % 60

		const contagem = `${this.formatarUnidadeDeTempo(hours)}:${this.formatarUnidadeDeTempo(
			minutes,
		)}:${this.formatarUnidadeDeTempo(seconds)}`
		

		localStorage.setItem("CONTAGEM_REGRESSIVA", JSON.stringify(contagem))
		return contagem
	}

	formatarUnidadeDeTempo(unit: number): string {
		return unit.toString().padStart(2, '0')
	}
}

export function horaParaSegundos(hora: string): number {
	const parts = hora.split(':')
	const hours = parseInt(parts[0], 10)
	const minutes = parseInt(parts[1], 10)
	const seconds = parseInt(parts[2], 10)

	return hours * 3600 + minutes * 60 + seconds
}
