var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[(!_vm.isVestcasa)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3 mr-3",attrs:{"data-cy":"botao-cpf","dark":"","icon":"","disabled":_vm.$attrs.disabled || _vm.venda && _vm.venda.tipoDeTransacao === 'Devolução'}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1784358676),model:{value:(_vm.mostraTrocarUtilizarCpf),callback:function ($$v) {_vm.mostraTrocarUtilizarCpf=$$v},expression:"mostraTrocarUtilizarCpf"}},[_c('v-list',[(_vm.mostraTrocarUtilizarCpf)?_c('v-list-item',{ref:"trocadorDeUtilizarApenasCpf",attrs:{"data-cy":"informar-cliente"},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.utilizarApenasCpf = !_vm.utilizarApenasCpf; }).apply(null, arguments)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.utilizarApenasCpf ? 'Informar Cliente' : 'Informar apenas o CPF'))])],1):_vm._e()],1)],1):_vm._e(),_c('BotaoDeConsultaCliente',{attrs:{"grupoEconomicoDaLoja":_vm.turnoDeVenda ? _vm.turnoDeVenda.pontoDeVenda.loja.grupoEconomico.id : null},on:{"acaoDeConsultarCliente":_vm.atualizarClienteDaVenda}}),_c(_vm.componenteDeCliente,_vm._g(_vm._b({ref:"campoDeCliente",tag:"component",staticClass:"sufixo campo-de-cliente",staticStyle:{"width":"100%","min-height":"93px"},attrs:{"data-cy":"campo-de-cpf","label":_vm.utilizandoCampoDeCpf ? 'C̲PF / CNPJ' : 'C̲liente',"loading":_vm.buscandoCliente,"auto-select-first":"","return-object":"","persistent-hint":"","hint":_vm.clienteHint,"append-outer-icon":_vm.obterIconeDoCampo,"disabled":_vm.carregandoOsItensDaVenda},on:{"click:append-outer":_vm.mostrarDialogoDeCliente,"click:clear":function () { return _vm.venda && (_vm.utilizandoCampoDeCpf ? _vm.venda.cpfDoCliente = null : _vm.venda.cliente = null); }},scopedSlots:_vm._u([(_vm.clienteHint)?{key:"message",fn:function(ref){
var message = ref.message;
return [(_vm.infosCliente)?[_c('div',{staticClass:"text-sm-body-2",class:{
						'info--text': _vm.infosCliente.tipoDoAlerta === 'info',
						'warning--text': _vm.infosCliente.tipoDoAlerta === 'warning',
						'success--text': _vm.infosCliente.tipoDoAlerta === 'success',
					},attrs:{"data-cy":"info-clube"}},_vm._l((message.split('\n')),function(frase,indice){return _c('div',{key:indice},[(!indice)?_c('i',{staticClass:"mdi mr-2",class:{
								'mdi-information': _vm.infosCliente && _vm.infosCliente.tipoDoAlerta === 'info',
								'mdi-alert': _vm.infosCliente && _vm.infosCliente.tipoDoAlerta === 'warning',
								'mdi-check': _vm.infosCliente && _vm.infosCliente.tipoDoAlerta === 'success',
							}}):_vm._e(),_c('span',{class:{ 'ml-6': !!indice }},[_vm._v(_vm._s(frase))])])}),0)]:_vm._e()]}}:null],null,true),model:{value:(_vm.cpfOuCnpjDoCliente),callback:function ($$v) {_vm.cpfOuCnpjDoCliente=$$v},expression:"cpfOuCnpjDoCliente"}},'component',_vm.$attrs,false),_vm.$listeners)),_c('DialogoDeEdicaoDeCliente',{ref:"dialogoEdicaoDeCliente",on:{"clienteAtualizado":_vm.clienteAtualizadoNaVenda,"confirmado":_vm.atualizarClienteDaVenda}}),_c('DialogoDeAniversarioDoCliente',{ref:"dialogoDeAniversarioDoCliente"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }