









































































































































import { Vue, Component, Ref, PropSync, Watch } from 'vue-property-decorator'
import { FormTurnoDeVenda } from '@/models/venda/TurnoDeVenda'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import SeletorDePdv from '@/components/venda/SeletorDePdv.vue'
import { PontoDeVenda, Usuario } from '@/models'
import { obrigatorio, positivo } from '@/shareds/regras-de-form'
import { AbrirCaixaUseCase, FindFechamentoDeCaixaUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import Tarefa from '@/helpers/Tarefa'
import { VendaModule } from '@/store/vuex/venda/VendaStore'

const ULTIMO_PDV = 'ultimo-pdv'

@Component({
	components: {
		SeletorDePdv,
		CampoDecimal,
	},
})
export default class DialogoDeAberturaDeCaixa extends Vue {
	@PropSync('value') mostra!: boolean | null
	@Ref() botaoDePdv!: { $el: HTMLButtonElement }
	@Ref() campoDeTroco!: CampoDecimal
	@Ref() form!: HTMLFormElement

	abrirCaixaUseCase = new AbrirCaixaUseCase()
	findFechamentoDeCaixaUseCase = new FindFechamentoDeCaixaUseCase()
	mostraSeletorDePdv = false
	liberarCampoDoTroco = false
	abrirDialogoDeMotivo = false
	verificandoTroco = false
	trocoAlterado = false
	salvando = false
	obrigatorio = obrigatorio
	observacao = ''
	positivo = positivo
	usuario: Usuario | null = null
	troco: null | number = null 

	turnoDeVenda: FormTurnoDeVenda = {
		pontoDeVenda: localStorage[ULTIMO_PDV]
			? JSON.parse(localStorage[ULTIMO_PDV])
			: null,
		fechamento: null,
		movimentacoes: [],
		tiposDePagamento: [],
		operador: null,
	}

	tarefaDeAbertura = new Tarefa('abrirCaixa', this.abrirCaixa)

	created(){
		this.usuario = UserLoginStore.usuario
		if(this.turnoDeVenda.pontoDeVenda) {
			this.buscarTroco()
		}
	}

	get nomeEPerfilDoUsuario(){
		if(!this.usuario) return ''
		return `${this.usuario?.nome} - ${this.usuario?.perfil.nome}`
	}
	
	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (mostra) {
			if(this.turnoDeVenda.pontoDeVenda) {
				this.buscarTroco()
			}
			setTimeout(() => {
				if (!this.turnoDeVenda.pontoDeVenda) {
					this.mostraSeletorDePdv = true
				} else {
					this.campoDeTroco.focus()
				}
			})
		} else {
			this.trocoAlterado = false
			this.verificandoTroco = false
		}
	}

	async verificaAlteracaoDeTroco() {
		try {
			this.verificandoTroco = true
			const pdvId = this.turnoDeVenda.pontoDeVenda.id
			const troco = await this.findFechamentoDeCaixaUseCase.findValorContadoEmDinheiroByPdvId(pdvId)
			if (this.troco !== troco) {
				this.abrirDialogoDeMotivo = true
				this.trocoAlterado = true
			} else {
				await this.confirmar()
			} 
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.verificandoTroco = false
		}
	}

	async confirmar() {
		this.tarefaDeAbertura.executar()
	}

	async abrirCaixa() {
		if (!this.form.validate()) return
		if (!this.turnoDeVenda.pontoDeVenda) {
			this.mostraSeletorDePdv = true
			return
		}

		delete this.turnoDeVenda.pontoDeVenda['loja']
		const turnoDeVenda: FormTurnoDeVenda = {
			...this.turnoDeVenda,
			pontoDeVenda: this.turnoDeVenda.pontoDeVenda,
			movimentacoes: [
				{
					id: '',
					tipoMovimentacao: 'Abertura',
					valor: this.troco as number,
					dataHoraMovimentacao: new Date(),
					observacao: this.observacao,
					anexos: [],
					operador: null,
					motivo: null,
				},
			],
		}
		const lojaString = localStorage.getItem('LOJA_DA_VENDA_STORAGE')
		let loja
		if (lojaString !== null) {
			loja = JSON.parse(lojaString);
		} else {
			AlertModule.setError("É nescessario selecionar uma loja")
		}
		
		if(!loja.configuracaoDaLoja.permiteAbrirLojaComNotaPendente){
			const podeAbrirCaixa = await this.abrirCaixaUseCase.podeAbrirCaixa(this.turnoDeVenda.pontoDeVenda.id)
			
			if(podeAbrirCaixa.length > 0){
				const listaNotas = podeAbrirCaixa.join(', ')
				AlertModule.setError("É necessário resolver os problemas das notas cujos identificadores são: " + listaNotas)
				return
			}
		}
		
		try {
			const turnoAberto = await this.abrirCaixaUseCase.execute(turnoDeVenda)
			localStorage[ULTIMO_PDV] = JSON.stringify(
				this.turnoDeVenda.pontoDeVenda,
			)
			this.$emit('abriu', turnoAberto)
		} catch (e) {
			AlertModule.setError(e)
		} finally {
			this.verificandoTroco = false
		}
	}

	selecionarPdv(pdv: PontoDeVenda) {
		this.turnoDeVenda.pontoDeVenda = pdv
		this.buscarTroco()
		this.mostraSeletorDePdv = false
		setTimeout(() => {
			this.campoDeTroco.focus()
		})
	}

	async buscarTroco() {
		try {
			const pdvId = this.turnoDeVenda.pontoDeVenda.id
			const troco = await this.findFechamentoDeCaixaUseCase.findValorContadoEmDinheiroByPdvId(pdvId)
			this.troco = troco
		} catch(e) {
			AlertModule.setError(e)
		}
	}

	get permiteAlterarTrocoNoCaixa() {
		return UserLoginStore.permiteRegraDeNegocio('pode-alterar-troco-no-caixa') || this.liberarCampoDoTroco
	}

	async authentication() {
		try{
			if (await VendaModule.autenticarAcaoTelaDeCaixa({ regra:'pode-alterar-troco-no-caixa' }))
				this.liberarCampoDoTroco = true
		}catch(e) {
			AlertModule.setError(e)
		}
	}
}
