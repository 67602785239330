













































































































































import Confirmacao from '@/components/ui/Confirmacao.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { ReabrirVendaUseCase,
	FindVendaUseCase, 
	FindModalidadeDeVendaUseCase,
} from '@/usecases'
import { Vue, Component, Watch, Ref, PropSync, Prop } from 'vue-property-decorator'
import {  Cliente, Produto, TipoDeProduto, ResumoDaVenda, Venda, ItemDaVenda } from '@/models'
import SeletorDeCliente from '@/views/application/clientes/SeletorDeCliente.vue'
import BuscaDeProdutoDropdown from '@/components/produto/BuscaDeProdutoDropdown.vue'
import CampoDeCodigoDaVenda from '@/components/venda/CampoDeCodigoDaVenda.vue'
import { obterDisplayClienteDaVenda } from '@/shareds/venda-shareds'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import AppLoading from '@/components/ui/AppLoading.vue'
import RodapePersonalizado from '@/components/ui/data-tables/RodapePersonalizado.vue'
import DialogoDeDetalhesDaVenda from '@/components/venda/DialogoDeDetalhesDaVenda.vue'
import BuscarVendaParaRetornoUseCase from '@/usecases/venda/BuscarVendaParaRetornoUseCase'

@Component({
	components: {
		Confirmacao,
		SeletorDeCliente,
		BuscaDeProdutoDropdown,
		CampoDeCodigoDaVenda,
		AppLoading,
		RodapePersonalizado,
		DialogoDeDetalhesDaVenda,
	},
})
export default class DialogoDeReabrirVenda extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref('confirmacao') confirmacao!: Confirmacao
	@PropSync('mostra', { type: Boolean, default: false }) syncMostra!: boolean
	@Ref() campoDeNumeroDaNota!: HTMLInputElement
	@Ref() campoDeNumeroDaVenda!: HTMLInputElement
	@Ref() checkboxDeSelecionarTodos!: HTMLInputElement
	@Ref() campoDeProduto!: HTMLInputElement
	@Prop() parametroIdentificador!: string | null
	mostra = false
	identificador = ''
	reabrirVendaUseCase = new ReabrirVendaUseCase()
	findModalidadeDeVendaUseCase = new FindModalidadeDeVendaUseCase()
	reabrindo = false
	devolver = false
	buscando = false
	pagina = 1
	parametros = criarParametrosDeBusca()
	vendasResumidas: ResumoDaVenda[] = []
	venda: Venda | null = null
	vendaOriginal: Venda | null = null
	findVendaUseCase = new FindVendaUseCase()
	selecionados: ItemDaVenda[] = []
	etapaDoRetorno: 'filtros' | 'pesquisas' | 'escolhendo-itens' = 'filtros'
	devolucaoParcial=false

	mostrar() {
		this.mostra = true
	}

	fechar() {
		this.mostra = false
	}

	async enviaIdentificador() {
		try {
			if (!this.form.validate()) return
			this.reabrindo = true

			this.devolver = await this.confirmacao.mostrar()

			await this.reabrirVendaUseCase.reabrir(this.parametros.identificador, this.devolver)

			if(VendaModule.vendaAtual && VendaModule.lojaDaVenda){
				const params = {
					isDevolucao:false,
					isDemonstracao:true,
					isVenda:false,
				}
				
				const modalidade = await this.findModalidadeDeVendaUseCase.findModalidadePadrao(VendaModule.lojaDaVenda.id, params)
			
				VendaModule.vendaAtual.modalidadeDeVenda = modalidade
				this.venda = VendaModule.vendaAtual
			}

			if(this.devolver) {
				this.$emit('devolver', true)
				this.fechar()
			} else {
				this.$emit('devolver', false)
				this.fechar()
			}

		} catch (e) {
			AlertModule.setError(e)
		} finally {
			this.reabrindo = false
			this.devolver = false
		}
		this.etapaDoRetorno = 'filtros'
		this.limparBusca
	}

	clienteNaoExiste() {
		AlertModule.setInfo('Cliente não encontrado')
	}

	get tiposDeProdutos() {
		return { tipos: ['Padrão', 'Variante'] as TipoDeProduto [] }
	}

	async buscarVenda() {
		if(this.parametros.identificador){
			await this.enviaIdentificador()
		}
		else if (this.parametros.cliente || this.parametros.produto) {
			await this.buscarListagemDeVendasPorPagina()
			if (this.parametros.cliente && this.parametros.produto) {
				if (this.vendasResumidas.length > 0) {
					this.selecionarVendaParaRetorno(this.vendasResumidas[0])
				} else {
					AlertModule.setInfo('Não foram encontradas vendas com as informações fornecidas')
					if (VendaModule.vendaAtual) {
						VendaModule.vendaAtual.cliente = this.parametros.cliente
						VendaModule.vendaAtual.cpfDoCliente = this.parametros.cliente.cnpjOuCpf
					}
				}
			} else {
				this.etapaDoRetorno = 'pesquisas'
			}
		} else {
			try {
				this.buscando = true
				this.venda = null
				this.venda = await BuscarVendaParaRetornoUseCase({
					...this.parametros,
					ambiente: this.turnoDeVenda?.pontoDeVenda.serieFiscal.ambiente,
					grupoEconomicoId: VendaModule.lojaDaVenda?.grupoEconomico.id,
					idLoja: VendaModule.lojaDaVenda?.id,
				})
				if (!this.venda.isDemonstracao && !this.venda.isConsignado) {
					AlertModule.setError('Esta venda não é uma demonstração/consignação, utilize a opção Troca')
					this.etapaDoRetorno ='filtros'
					this.parametros.identificador = ''
					return
				}
				this.vendaOriginal = await this.findVendaUseCase.get(this.venda.id)
				this.selecionados = []
				if (this.parametros.produto) {
					this.venda.itens.forEach(item => {
						if (item.produto.sku === this.parametros.produto?.sku) this.selecionados.push(item)
					})
				}

				this.parametros.identificador = this.venda.identificador ? this.venda.identificador : ''
				await this.enviaIdentificador()
			} catch (error: any) {
				AlertModule.setError(error)
				this.etapaDoRetorno ='filtros'
				this.parametros.identificador = ''
				return
			} finally {
				this.reabrindo = false
			}
		}
	}

	async buscarListagemDeVendasPorPagina() {
		if (!VendaModule.lojaDaVenda) return
		if (!this.parametros.cliente && !this.parametros.produto) return

		let vendasPaginadas

		try {
			this.reabrindo = true
			vendasPaginadas = await this.findVendaUseCase.list({
				lojaId: VendaModule.lojaDaVenda.id || undefined,
				cliente: this.parametros.cliente ? this.parametros.cliente.cnpjOuCpf : undefined,
				identificadorProduto: this.parametros.produto ? this.parametros.produto.sku : undefined,
				vendasConsignadaDemonstracao: true,
				isProcessoDeDevolucao: false,
				situacoesDeVenda: "CONCLUIDA",
				somenteVendasSemDevolucaoRealizada: true,
				page: this.paginacao.page,
				size: this.paginacao.itemsPerPage,
				sort: 'dataHora,desc',
				grupoEconomicoId: VendaModule.lojaDaVenda.grupoEconomico.id,
			}) 
		} catch(error) {
			AlertModule.setError(error)
			this.etapaDoRetorno ='filtros'
			this.parametros.identificador = ''
			return
		} finally {
			this.reabrindo = false
		}

		this.vendasResumidas = vendasPaginadas.content
		this.paginacao.itemsLength = vendasPaginadas.totalElements
	}

	selecionarVendaParaRetorno(vendaSelecionada: ResumoDaVenda | Venda) {
		if (!vendaSelecionada.identificador) return

		this.parametros.identificador = vendaSelecionada.identificador
		this.enviaIdentificador()
	}

	headersVendas = [
		{ text: 'Identificador', value: 'identificador', sortable: false },
		{ text: 'Loja', value: 'loja', sortable: false },
		{ text: 'Cliente', value: 'displayCliente', sortable: false },
		{ text: 'Data', value: 'diplayDataHora', sortable: false },
		{ text: 'Ações', value: 'actions', align: 'center' },
	]

	paginacao = {
		page: 0,
		itemsPerPage: 10,
		itemsLength: 0,
	}

	get isClienteInformado() {
		return this.parametros.cliente && typeof this.parametros.cliente === 'object'
	}

	get isProdutoInformado() {
		return this.parametros.produto && typeof this.parametros.produto === 'object'
	}

	get vendasFormatadas() {
		return this.vendasResumidas.map(venda => ({
			...venda,
			displayCliente: obterDisplayClienteDaVenda(venda),
			diplayDataHora: this.diplayDataHora(venda),
		}))
	}

	get turnoDeVenda() {
		return VendaModule.turnoDeVenda
	}

	resetarParametros() {
		this.parametros.identificador = ''
		this.parametros.cliente = null
	}

	voltar() {
		if (this.etapaDoRetorno !== 'filtros') {
			this.etapaDoRetorno = 'filtros'
			this.focarNoPrimeiroCampo()
		} else {
			this.parametros.identificador = ''
			this.mostra = false
		}
	}

	diplayDataHora(venda: ResumoDaVenda) {
		if (!venda.dataHora) return ''
		return dateTimeToPtBrFormat(venda.dataHora)
	}

	atualizarPagina(page) {
		this.paginacao.page += page
		this.buscarListagemDeVendasPorPagina()
	}

	focarNoPrimeiroCampo() {
		setTimeout(() => {
			if (this.etapaDoRetorno === 'filtros') {
				if (this.parametros.numeroDaNota || this.parametros.serieFiscal) {
					this.campoDeNumeroDaNota.focus()
				} else {
					this.campoDeNumeroDaVenda.focus()
				}
			} else {
				this.checkboxDeSelecionarTodos.focus()
			}
		})
	}

	@Watch('mostra', { immediate: true })
	onChangeMostra(mostra: boolean, mostraAnterior: boolean) {
		if (!mostra) return

		this.parametros = criarParametrosDeBusca()
		setTimeout(() => {
			this.form.resetValidation()
			this.focarNoPrimeiroCampo()
		})

		if (this.parametroIdentificador !== null) {
			this.parametros.identificador = this.parametroIdentificador
		}
		if (mostra && !mostraAnterior) {
			this.identificador = ''
			window.requestAnimationFrame(() => {
				this.form.resetValidation()
			})
		}
	}

	limparBusca(){
		this.parametros.identificador = ''
		this.parametros.idLoja = ''
		this.parametros.numeroDaNota = ''
		this.parametros.serieFiscal = ''
		this.parametros.cliente = null
		this.parametros.produto = null
	}
}

function criarParametrosDeBusca() {
	return {
		identificador: '',
		idLoja: '',
		numeroDaNota: '',
		serieFiscal: '',
		cliente: null as Cliente | null,
		produto: null as Produto | null,
	}
}
