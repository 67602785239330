import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import pdfMake from 'pdfmake/build/pdfmake'
import type { Content, ContentTable, TDocumentDefinitions } from 'pdfmake/interfaces'
import vfs from 'pdfmake/build/vfs_fonts.js'
import {
	FechamentoDeCaixa,
	ItemDeFechamento,
	MovimentacaoDeCaixa,
	TurnoDeVenda,
} from '@/models'
import { formatarMoeda } from '../formatadores'
import print from 'print-js'

window.pdfMake.vfs = vfs.pdfMake.vfs

async function criarDocumentoDeFechamentoDoCaixa(
	turnoDeVenda: TurnoDeVenda,
	fechamentoDeCaixa: FechamentoDeCaixa,
): Promise<string> {
	const dataHoraDoFechamento = fechamentoDeCaixa.dataHoraFechamento || new Date()
	const dataHoraDeAbertura = turnoDeVenda.movimentacoes.find(
		({ tipoMovimentacao }) => tipoMovimentacao === 'Abertura',
	)?.dataHoraMovimentacao || ''

	const docDefinition: TDocumentDefinitions = {
		pageSize: {
			height: 'auto',
			width: 230,
		},
		content: [
			{ text: `Loja ${turnoDeVenda.pontoDeVenda?.loja.nomeFantasia || ''}\n\n`, bold: true, fontSize: 9, alignment: 'center' },
			{ text: `Ponto de Venda ${turnoDeVenda.pontoDeVenda.nome}`, style: 'subHeader' },
			{ text: `Operador ${turnoDeVenda.operador.nome}`, style: 'subHeader' },
			{ text: `Abertura ${dateTimeToPtBrFormat(dataHoraDeAbertura)}`, style: 'subHeader' },
			{ text: `Fechamento ${dateTimeToPtBrFormat(dataHoraDoFechamento)}\n\n`, style: 'subHeader' },
			{ text: 'Vendas\n', style: 'columnsTitle' },
			buildTabelaDosItensDeFechamento(fechamentoDeCaixa.itensDeFechamento),
			{ text: '\nMovimentações\n', style: 'columnsTitle' },
			buildTabelaDasMovimentacoesDeCaixa(turnoDeVenda.movimentacoes),
		],
		styles: {
			tableStyle: {
				fontSize: 7,
				alignment: 'center',
			},
			subHeader: {
				fontSize: 8,
				italics: true,
				alignment: 'center',
			},
			columnsTitle: {
				bold: true,
				fontSize: 7,
				alignment: 'center',
			},
		},
	}
	return new Promise(resolve => {
		pdfMake.createPdf(docDefinition).getBase64((result) => resolve(result))
	})
}

export async function imprimirFechamentoDeCaixa(
	turnoDeVenda: TurnoDeVenda,
	fechamentoDeCaixa: FechamentoDeCaixa,
): Promise<void> {
	const fechamentoDeCaixaBase64: string = await criarDocumentoDeFechamentoDoCaixa(turnoDeVenda, fechamentoDeCaixa)
	return new Promise((resolve, reject) => {
		print({
			printable: fechamentoDeCaixaBase64,
			type: 'pdf',
			base64: true,
			onError: reject,
			onLoadingEnd: resolve,
		})
	})
}

function buildTabelaDosItensDeFechamento(itensDoFechamento: ItemDeFechamento[]): ContentTable {
	const campos: (keyof ItemDeFechamento | 'Diferença')[] = [
		'forma',
		'valorEsperado',
		'valorContado',
		'Diferença',
	]
	const cabecalho = [
		'Forma',
		'Esperado',
		'Contado',
		'Diferença',
	].map(valor => ({
		text: valor, bold: true, fontSize: 7,
	}))

	function displayItemDoFechamento(item: ItemDeFechamento, campo: keyof ItemDeFechamento | 'Diferença') {
		if (campo == 'forma') return item[campo]
		return `R$ ${formatarMoeda(Number(campo === 'Diferença'
			? `${calcularDiferencaDosValoresDoCaixa(item)}`
			: item[campo]))}`
	}

	return {
		style: 'tableStyle',
		table: {
			headerRows: 1,
			widths: [ 28, 34, 34, 34 ],
			body: [
				cabecalho,
				...itensDoFechamento.map(item =>
					campos.map(campo => ({
						text: displayItemDoFechamento(item, campo),
						bold: true,
						fontSize: 6,
					})),
				),
				totalizarItensDeFechamento(itensDoFechamento),
			],
		},
		layout: {
			hLineWidth: () => 0.3,
			vLineWidth: () => 0.3,
		},
	}
}

function buildTabelaDasMovimentacoesDeCaixa(movimentacoes: MovimentacaoDeCaixa[]): ContentTable {
	const campos: (keyof MovimentacaoDeCaixa)[] = [
		'dataHoraMovimentacao',
		'tipoMovimentacao',
		'valor',
		'observacao',
	]

	const cabecalho = [
		'Hora',
		'Tipo',
		'Valor',
		'Observação',
	].map(valor => ({
		text: valor, bold: true, fontSize: 7,
	}))

	return {
		style: 'tableStyle',
		table: {
			headerRows: 1,
			widths: [ 36, 24, 31, 39 ],
			body: [
				cabecalho,
				...movimentacoes.map(movimentacao =>
					campos.map(campo => ({
						text: displayCampoDaMovimentacao(movimentacao, campo),
						bold: true,
						fontSize: 6,
					})),
				),
			],
		},
		layout: {
			hLineWidth: () => 0.3,
			vLineWidth: () => 0.3,
		},
	}
}

function displayCampoDaMovimentacao(movimentacao: MovimentacaoDeCaixa, campo: keyof MovimentacaoDeCaixa) {
	switch(campo) {
		case 'valor': return `R$ ${formatarMoeda(Number(movimentacao[campo]))}`
		case 'dataHoraMovimentacao':  return dateTimeToPtBrFormat(movimentacao[campo])
		default: return movimentacao[campo]
	}
}

function totalizarItensDeFechamento(itensDoFechamento: ItemDeFechamento[]): Content[] {
	const valorTotalEsperado = itensDoFechamento.reduce(
		(total, { valorEsperado }) => total + Number(valorEsperado), 0,
	)
	const valorTotalContado = itensDoFechamento.reduce(
		(total, { valorContado }) => total + Number(valorContado), 0,
	)
	const valorTotalDiferenca = itensDoFechamento.reduce(
		(total, { valorContado, valorEsperado }) =>
			total + Number(valorContado) - Number(valorEsperado),
		0,
	)
	return [
		{ text: 'Total', bold: true, fontSize: 6 },
		{ text: `R$ ${formatarMoeda(valorTotalEsperado)}`, bold: true, fontSize: 6 },
		{ text: `R$ ${formatarMoeda(valorTotalContado)}`, bold: true, fontSize: 6	},
		{ text: `R$ ${formatarMoeda(valorTotalDiferenca)}`, bold: true, fontSize: 6	},
	]
}

function calcularDiferencaDosValoresDoCaixa(itemDoFechamento: ItemDeFechamento) {
	return itemDoFechamento.valorContado - itemDoFechamento.valorEsperado
}
