
















































import { Vue, Component, PropSync } from 'vue-property-decorator'
import { ReimpressaoModule } from '@/store/vuex/reimpressao/ReimpressaoStore'
import printPdfMake from '@/shareds/print/printPdfMake'

@Component({
	components: {},
})
export default class DialogoDeReimpressao extends Vue {
	@PropSync('mostra', { type: Boolean, default: false }) syncMostra!: boolean

	ReimpressaoModule = ReimpressaoModule

	voltar() {
		this.$emit('voltar')
	}

	async reimprimir(reimpressao) {
		printPdfMake(reimpressao.docDefinition)
	}
}
