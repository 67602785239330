


























































































import { ItemDaVenda, Produto } from '@/models'
import type { Loja } from '@/models'
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { formatarMoeda } from '@/shareds/formatadores'
import { ConsultaDeProduto, ConsultarProdutoUseCase } from '@/usecases/venda/ConsultarProdutoUseCase'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import CampoDeItemDeVenda from '@/components/venda/CampoDeItemDeVenda.vue'
import { isVestcasa } from '@/shareds/utils'
import { VendaModule } from '@/store/vuex/venda/VendaStore'

@Component({
	components:{
		CampoDeItemDeVenda,
	},
})
export default class DialogoDeConsultaDeProduto extends Vue {
	@Prop({ type: Object }) loja!: Loja
	@Ref() readonly campoDeBusca!: CampoDeItemDeVenda
	
	produtoBipado: Produto | null = null
	formatarMoeda = formatarMoeda 
	mostra = false
	vestcasa = isVestcasa
	consultaDeProduto: ConsultaDeProduto | null = null
	mostraConsultaDeProduto = false

	get nomeDaTabelaDePreco(){
		if (!this.consultaDeProduto) return []
		return this.consultaDeProduto.precos.flatMap( tabela => tabela)
	}

	get buscaUnica() {
		return this.vestcasa
	}

	get lojaDaVenda() {
		return VendaModule.lojaDaVenda
	}

	mostrar(){
		this.mostra = true	
	}

	created() {
		document.addEventListener('keydown', this.atalhoDeSelecaoDeProduto)
	}

	destroyed() {
		document.removeEventListener('keydown', this.atalhoDeSelecaoDeProduto)
	}

	atalhoDeSelecaoDeProduto(event: KeyboardEvent) {
		if (event.altKey && event.key.toLowerCase() === 's') {
			this.focarBusca()
		}
	}

	focarBusca() {
		setTimeout(() => {
			this.campoDeBusca.focus()
		})
	}
	
	fecharDialogo(){
		this.mostra = false
		this.mostraConsultaDeProduto = false
	}

	async consultarProduto(item: ItemDaVenda) {
		if (!this.lojaDaVenda) return
		try {
			this.mostraConsultaDeProduto = true
			this.produtoBipado = item.produto as Produto
			this.consultaDeProduto = await new ConsultarProdutoUseCase().executar({
				loja: this.lojaDaVenda,
				produtoId: item.produto.id,
			})
		} catch (error: any){
			AlertModule.setError(error)
		}
	}

	@Watch('mostra')
	onChangeMostra(){
		if(!this.mostra) return this.mostraConsultaDeProduto = false
	}
}

